import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { Tomorrow } from '@/shared/images/icons/Tomorrow';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius, borders } from '@/styles/Themes/Metrics';
import { Palette } from '@/styles/Themes/Palette';
import { Transition } from '@/styles/Themes/KeyFrames';

export const CardWidth = styled.a`
    display: block;
    gap: 12px;

    ${Screen.mobile} {
        width: 100%;
    }
`;

export const PreviewWrapper = styled.div<{ numberOfItems: number }>`
    position: relative;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    display: grid;
    height: 310px;
    overflow-x: hidden;
    overflow-y: hidden;
    cursor: pointer;
    width: ${({ numberOfItems }) => (numberOfItems === 1 ? '100%' : '316px')};

    ${Screen.ipad} {
        height: 336px;
        width: ${({ numberOfItems }) => (numberOfItems === 2 ? '344px' : '340px')};
    }
    ${Screen.desktop} {
        height: 274px;
        width: 248px;
    }

    &:hover {
        box-shadow: ${cssVars.shadow_card_hover};
        ${Transition.normal};
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    padding: 12px 12px 0;
    height: 199px;
    ${Screen.ipad} {
        padding: 12px 12px 0;
        height: 222px;
    }
    ${Screen.desktop} {
        padding: 12px 12px 0;
        height: 149px;
    }
`;

export const ImgWrap = styled.div`
    position: relative;
    border-radius: ${radius.small};
    overflow: hidden;
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    height: 187px;

    ${Screen.ipad} {
        height: 210px;
    }
    ${Screen.desktop} {
        height: 147px;
    }
`;

export const Title = styled.span`
    color: ${cssVars.fg_gray1_white};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    ${Text.Mobile.large.semibold}
    ${Screen.ipad} {
        margin: 5px 0;
    }
`;

export const PriceWrapper = styled.div`
    color: ${Palette.gray.gray1};
    background-color: ${Palette.white.normal};
    height: 28px;
    min-width: 52px;
    border-radius: ${radius.large};
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 4px 14px;
    border: ${borders.small} ${Palette.gray.gray4};

    ${Screen.ipad} {
        margin-right: 20px;
    }
    ${Screen.desktop} {
        height: 24px;
        ${Text.Desktop.large.semibold};
    }
`;

export const StrikeOut = styled.span`
    display: flex;
    align-items: center;
    text-decoration: line-through;
    margin-left: 4px;
    ${Text.Full.normal.regular};

    &::before {
        content: '$';
    }
`;

export const Price = styled.span`
    display: flex;
    align-items: center;
    ${Text.Mobile.medium.semibold};
    color: ${Palette.gray.gray1};

    &::before {
        content: '$';
    }
    ${Screen.ipad} {
        ${Text.Ipad.large.semibold};
    }
    ${Screen.desktop} {
        ${Text.Desktop.normal.semibold};
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    height: auto;
    padding: 0 16px 12px;

    ${Screen.ipad} {
        padding: 0 16px 0;
    }
`;

export const DurationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${cssVars.fg_gray2_gray3};
    font-size: 13px;

    margin: 0 0 10px;
    ${Screen.ipad} {
        margin: 0 0 14px;
    }
`;

export const RatingCount = styled.span`
    margin-left: 4px;
`;

export const RatingText = styled.span`
    margin-right: 4px;
`;

export const ClockIcon = styled(Tomorrow)`
    display: flex;
    align-self: center;
`;

export const Time = styled.div`
    text-align: end;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
`;

export const Reviews = styled.div`
    display: flex;
`;

export const Minutes = styled.div`
    display: flex;
    flex: 1 0 auto;
`;

export const NoReviews = styled.div`
    color: ${Palette.sand.darkSand};
`;

// export const NewToTripshock = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: 25px;
//     right: -45px;
//     rotate: 45deg;
//     background-color: ${cssVars.bg_white_dark3};
//     width: 175px;
//     height: 34px;
//     color: ${Palette.green.normal};
//     ${Text.Full.small.semibold};
//     padding: 3px 65px;
//     text-align: center;
//     ${Screen.desktop} {
//         height: 40px;
//         width: 196px;
//         right: -55px;
//     }
// `;
